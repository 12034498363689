export const getStatusColor = (status: string) => {
  switch (status) {
    case "Halted":
      return { bg: "#FEF3F2", bc: "#B42318" };
    case "Potential risk":
      return { bg: "#FEF0C7", bc: "#B54708" };
    case "In progress":
      return { bg: "#ECFDF3", bc: "#027A48" };
    case "Delivered":
      return { bg: "#EEF4FF", bc: "#3538CD" };
    default:
      return { bg: "#FEF0C7", bc: "#B54708" };
  }
};
