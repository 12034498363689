import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { AbilityContext } from "../../components/Can";
import { OnboardingModal } from "../../components/OnboardingModal";
import { TaskModal } from "../../components/TaskModal";
import { useStores } from "../../hooks/useStores";
import { Board } from "../Board";
import { Dashboard } from "../Dashboard";
import { IntegrationsScreen } from "../Integrations";
import { Sidebar } from "../Sidebar";
import { TimeButton } from "../TimerButton";
//import { NewUserPool } from "../components/NewUserPool";
import { Helmet } from "react-helmet";
import { CookieConsent } from "../../components/CookieConsent/CoookieConsent";
import keys from "../../config/keys";
import { getGoogleTagManager } from "../../services/googleTagManager";
import i18n from "../../services/i18n";
import { getUsetifulScript } from "../../services/usetiful";
import { OrganizationLayout } from "../OrganizationLayout";
import { ProjectDetails } from "../ProjectDetails";
import { Projects } from "../Projects";
import { UserProfile } from "../UserProfile";
import { TeamPlanTheta } from "../TeamPlanTheta";
import { SiderBarBeta } from "../SidebarBeta";
import { Divider } from "@chakra-ui/react";

export const AppLayout: React.FC = observer(() => {
  const { session } = useStores();
  const { boot } = useIntercom();

  useEffect(() => {
    const script = document.createElement("script");

    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    session.fetchActiveTimer();

    if (session.user && i18n.language !== session.user.language) {
      i18n.changeLanguage(session.user.language);
      localStorage.setItem("i18nextLng", session.user.language);
    }

    if (session.user && keys.NODE_ENV === "production") {
      boot({
        name: session.user.nominative,
        userId: session.user.uid,
        email: session.user.email,
      });
    }
  }, [boot, session]);
  const menuItems = [
    { name: "Home", path: "/" },
    {
      name: "Organization",
      path: `/organization/${session.user?.organization?.uid}/customers`,
      children: [
        {
          name: "customers",
          path: `/organization/${session.user?.organization?.uid}/customers`,
          children: [
            {
              name: "users",
              path: `/organization/${session.user?.organization?.uid}/users`,
            },
          ],
        },
        {
          name: "workflows",
          path: `/organization/${session.user?.organization?.uid}/workflows`,
        },
      ],
    },
  ];

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "row" }}>
      {session.user && session.userRole && (
        <AbilityContext.Provider value={session.userRole.acls}>
          <Helmet>
            {session.userHasAcceptedCookies && (
              <script type="text/javascript">
                {getUsetifulScript(session.user.uid, session.user.language)}
              </script>
            )}
            {session.userHasAcceptedCookies && keys.GOOGLE_TAG_MANAGER_ID && (
              <script type="text/javascript">{getGoogleTagManager()}</script>
            )}
          </Helmet>
          <OnboardingModal />
          {/* <ModifySideBar menuItems={menuItems} /> */}
          {/* <Sidebar /> */}
          <SiderBarBeta />
          <Divider orientation='vertical' color={'#D4D2D2'} pr={"5px"} />
          <TimeButton />
          <CookieConsent />
          {/* <NewUserPool /> */}
          <Routes>
            <Route path={"/board"} element={<Board />} />
            <Route path={"/teamplan3"} element={<TeamPlanTheta />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route path={"/integrations"} element={<IntegrationsScreen />} />
            <Route path={"/*"} element={<OrganizationLayout />} />
            <Route path={"/projects"} element={<Projects />} />
            <Route path={"/projects/:projectId"} element={<ProjectDetails />} />
            <Route path={"/profile"} element={<UserProfile />} />
          </Routes>
          <TaskModal />
        </AbilityContext.Provider>
      )}
    </div>
  );
});
