import {
  toOverhead,
  toProjectLinks,
  toProjectStatstics,
  toProjectUpdates,
  toTimesheetByProjectRow,
} from "./../utils";
import keys from "../../config/keys";
import {
  toCustomer,
  toEvent,
  toOrganization,
  toProject,
  toProjectCategory,
  toProjectExpense,
  toProjectNote,
  toProjectReport,
  toRole,
  toTask,
  toTaskCategory,
  toTeamReportRow,
  toTimer,
  toTimesheetByDayRow,
  toUser,
  toUserInsights,
} from "../utils";
import {
  EditedProject,
  EditedProjectExpense,
  Organization,
  OrganizationRole,
  ProjectCategory,
  ProjectNote,
  ProjectReport,
  Role,
  TimeBlockingEvent,
  User,
  EditedProjectNote,
  TimesheetRowByDay,
  Customer,
  UserInsights,
  Task,
  TeamReport,
  TaskCategory,
  TeamReportRow,
  TimesheetRowByProject,
  ProjectStatstics,
  EditedProjectExpenseBeta,
  ProjectLinks,
  ProjectUpdate,
  Overhead,
} from "../../types";
import Project from "../../models/Project";
import Expense from "../../models/Expense";
import { Moment } from "moment";
import Timer from "../../models/Timer";
import storageServices from "../storage";

let sessionToken: string | undefined;

const setSession = (token: string) => {
  sessionToken = token;
};

const clearSession = () => {
  sessionToken = undefined;
};

export const makeApiRequest = async (
  method: "POST" | "GET" | "PATCH" | "DELETE",
  path: string,
  body?: any
): Promise<any> => {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  if (sessionToken) {
    headers.append("Authorization", `Bearer ${sessionToken}`);
  }

  try {
    const response = await fetch(`${keys.API_BASE_URL}${path}`, {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: headers,
    });

    if (!response.ok) {
      const message = await parseErrorMessage(response);
      throw new Error(`${response.status}: ${response.statusText}. ${message}`);
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.warn(`Failed request ${method} ${path}`, err);
    throw err;
  }
};

const parseErrorMessage = async (response: Response) => {
  try {
    const data = await response.json();
    if (!data.ErrorMessage) {
      throw new Error();
    }
    return data.ErrorMessage;
  } catch (err) {
    return "";
  }
};

const signUser = async (
  uid: User["uid"],
  nominative: User["nominative"],
  email: User["email"],
  image_url: User["imageUrl"]
): Promise<User> => {
  const payload = { uid, nominative, email, image_url };
  const response = await makeApiRequest("POST", `auth/sign`, payload);
  return toUser(response);
};

const login = async (email: User["email"], password: string): Promise<User> => {
  // delete old token
  storageServices.removeItem("session");
  const body = {
    email,
    password,
  };
  const response = await makeApiRequest("POST", "auth/basic", body);

  storageServices.setItem("session", { token: response.token });
  setSession(response.token);

  return toUser(response.user);
};

const basicSignUp = async (
  email: User["email"],
  password: string,
  nominative: User["nominative"]
): Promise<User> => {
  const body = {
    email,
    password,
    nominative,
  };
  const response = await makeApiRequest("POST", "auth/basic/signup", body);

  storageServices.setItem("session", { token: response.token });
  setSession(response.token);

  return toUser(response.user);
};

const getCurrentUser = async (): Promise<User | null> => {
  const sessionData = await storageServices.getItem("session");

  if (!sessionData) return null;
  setSession(sessionData["token"]);
  const result = await makeApiRequest("GET", `auth/me`);

  return toUser(result);
};

const lostPassword = async (email: User["email"]): Promise<boolean> => {
  await makeApiRequest(
    "GET",
    `auth/basic/lost-password?email=${encodeURIComponent(email)}`
  );

  return true;
};

const changePassword = async (
  email: User["email"],
  password: string,
  token: string
): Promise<boolean> => {
  await makeApiRequest("POST", "auth/basic/change-password", {
    email,
    password,
    token,
  });

  return true;
};

const updateUser = async (
  userId: User["uid"],
  data: Partial<User>
): Promise<User> => {
  const payload = {};

  if (data.language) {
    payload["language"] = data.language;
  }

  if (data.preferences) {
    payload["preferences"] = data.preferences;
  }

  if (!!data.termsOfUse) {
    payload["terms_of_use"] = data.termsOfUse;
  }

  if (!!data.privacyPolicy) {
    payload["privacy_policy"] = data.privacyPolicy;
  }

  if (data.cookieConsent !== undefined) {
    payload["cookie_consent"] = data.cookieConsent;
  }

  if (data.status) {
    payload["status"] = data.status;
  }

  const response = await makeApiRequest("PATCH", `user/${userId}`, payload);
  return toUser(response);
};

const fetchTimeBlockingEvents = async (
  userId: User["uid"],
  startDate: Date,
  endDate: Date
): Promise<TimeBlockingEvent[]> => {
  const response = await makeApiRequest(
    "GET",
    `user/${userId}/time-blocking?startTime=${startDate.toISOString()}&endTime=${endDate.toISOString()}`
  );
  return response.map((item) => toEvent(item));
};

const addTimeBlockingEvent = async (
  userId: User["uid"],
  event: TimeBlockingEvent,
  organizationId: Organization["uid"]
): Promise<TimeBlockingEvent> => {
  const body = {
    title: event.title,
    start_time: event.startTime,
    end_time: event.endTime,
    color: event.color,
    source: event.source,
    source_id: event.sourceId,
    event_type: event.eventType,
    project_id: event.projectId,
    elapsed_time: event.elapsedTime,
    completed: !!event.completed,
    recurrence_id: event.recurrenceId,
    recurrence: event.recurrence
      ? {
        every: event.recurrence.every,
        frequency: event.recurrence.frequency,
        until: event.recurrence.until,
        repeat: event.recurrence.repeat,
        day_of_weeks: event.recurrence.dayOfWeeks,
      }
      : null,
    organization_id: organizationId,
    category_id: event.categoryId,
    task_linked_id: event.taskLinkId,
    calanderId: event.calanderId,
  };
  const response = await makeApiRequest(
    "POST",
    `user/${userId}/time-blocking`,
    body
  );
  return toEvent(response);
};

const setTimeBlockingEvent = async (
  userId: User["uid"],
  eventId: TimeBlockingEvent["uid"],
  event: TimeBlockingEvent
): Promise<TimeBlockingEvent> => {
  const body = {
    title: event.title,
    start_time: event.startTime,
    end_time: event.endTime,
    color: event.color,
    elapsed_time: event.elapsedTime,
    completed: event.completed,
    event_type: event.eventType,
    project_id: event.projectId,
    category_id: event.categoryId,
    source: event.source ?? undefined,
    source_id: event.sourceId ?? undefined,
    task_linked_id: event.taskLinkId ?? undefined,
    calanderId: event.calanderId ?? undefined,
  };

  // alert(JSON.stringify(body));

  const response = await makeApiRequest(
    "PATCH",
    `user/${userId}/time-blocking/${eventId}`,
    body
  );
  return toEvent(response);
};

const deleteTimeBlockingEvent = async (
  userId: User["uid"],
  eventId: TimeBlockingEvent["uid"],
  deleteRecurrence: boolean = false
): Promise<TimeBlockingEvent> => {
  const response = await makeApiRequest(
    "DELETE",
    `user/${userId}/time-blocking/${eventId}${deleteRecurrence ? "?recurrences=true" : ""
    }`
  );
  return toEvent(response);
};

const getTodoistSignedUrl = async () => {
  const response = await makeApiRequest("GET", `connect/todoist`);
  return response;
};

const getTodoistTasks = async () => {
  const response = await makeApiRequest("GET", `connect/todoist/tasks`);

  return response;
};

const disableTodoist = async () => {
  const response = await makeApiRequest("DELETE", `connect/todoist`);
  return response;
};

const fetchOrganization = async (
  organizationId: Organization["uid"]
): Promise<Organization> => {
  const response = await makeApiRequest(
    "GET",
    `organization/${organizationId}`
  );

  return toOrganization(response);
};

const createOrganization = async (
  userId: User["uid"],
  organization: Organization,
  userLanguage: string
): Promise<OrganizationRole & { invalidDomains?: Array<string> }> => {
  const response = await makeApiRequest("POST", `user/${userId}/organization`, {
    ...organization,
    userLanguage,
  });
  return {
    organization: toOrganization(response.organization),
    role: response.role,
    invalidDomains: response.invalidDomains,
  };
};

const updateOrganization = async (
  userId: User["uid"],
  organizationId: Organization["uid"],
  organization: Organization
): Promise<{ updated: boolean; invalidDomains: Array<string> }> => {
  const response = await makeApiRequest(
    "PATCH",
    `user/${userId}/organization/${organizationId}`,
    organization
  );

  return response;
};

const fetchOrganizationRoles = async (
  organizationId: Organization["uid"]
): Promise<Role[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/role`
  );

  return response.map((item) => toRole(item));
};

const createOrganizationRole = async (
  organizationId: Organization["uid"],
  role: Role
): Promise<Role> => {
  const payload = {
    name: role.name,
    acls: JSON.stringify(role.acls),
  };
  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/role`,
    payload
  );

  return toRole(response);
};

const updateOrganizationRole = async (
  organizationId: Organization["uid"],
  role: Role
): Promise<Role> => {
  const payload = {
    name: role.name,
    acls: JSON.stringify(role.acls),
  };
  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/role/${role.uid}`,
    payload
  );

  return toRole(response);
};

const fetchOrganizationUsers = async (
  organizationId: Organization["uid"],
  onlyActiveUsers: boolean = false
): Promise<User[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/user${onlyActiveUsers ? "?only_active_users=true" : ""
    }`
  );

  return response.map((item) => toUser(item));
};

const inviteUser = async (
  organizationId: Organization["uid"],
  email: User["email"],
  nominative: User["nominative"],
  roleId: Role["uid"]
) => {
  const payload = {
    email,
    nominative,
    organization_role_id: roleId,
  };
  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/user/invite`,
    payload
  );

  return response;
};

const updateOrganizationUser = async (
  organizationId: Organization["uid"],
  userId: User["uid"],
  roleId: Role["uid"]
  // hourlyCost: User["hourlyCost"],
  // contractualHours: User["contractualHours"],
  // productivityTarget: User["productivityTarget"]
): Promise<User> => {
  const payload = {
    role_id: roleId,
    // hourly_cost: hourlyCost,
    // contractual_hours: contractualHours,
    // productivity_target: productivityTarget,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/user/${userId}`,
    payload
  );

  return toUser(response);
};

const fetchProjectCategories = async (
  organizationId: Organization["uid"]
): Promise<ProjectCategory[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/project-category`
  );

  return response.map((item) => toProjectCategory(item));
};

const createProjectCategory = async (
  organizationId: Organization["uid"],
  category: ProjectCategory
): Promise<ProjectCategory> => {
  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/project-category`,
    category
  );

  return toProjectCategory(response);
};

const updateProjectCategory = async (
  organizationId: Organization["uid"],
  category: ProjectCategory
): Promise<ProjectCategory> => {
  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/project-category/${category.uid}`,
    category
  );

  return toProjectCategory(response);
};

const deleteProjectCategory = async (
  organizationId: Organization["uid"],
  categoryId: ProjectCategory["uid"]
): Promise<boolean> => {
  await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/project-category/${categoryId}`
  );

  return true;
};

const fetchProjects = async (
  organizationId: Organization["uid"]
): Promise<Project[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/project`
  );

  return response.map((item) => toProject(item));
};

const fetchProject = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"]
): Promise<Project> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/project/${projectId}`
  );

  return toProject(response);
};

const fetchUserProjects = async (
  organizationId: Organization["uid"],
  userId: User["uid"]
): Promise<Project[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/user/${userId}/project`
  );

  return response.map((item) => toProject(item));
};

const createProject = async (
  organizationId: Organization["uid"],
  newProject: EditedProject
): Promise<Project> => {
  const payload = {
    title: newProject.title,
    customer_id: newProject.customerId,
    external_reference: newProject.externalReference,
    not_billable: newProject.notBillable,
    budget: newProject.budget,
    marginability_percentage: newProject.marginabilityPercentage,
    tolerance_days: newProject.toleranceDays,
    project_manager_id: newProject.projectManagerId,
    category_id: newProject.categoryId,
    contact_name: newProject.contactName,
    contact_surname: newProject.contactSurname,
    contact_email: newProject.contactEmail,
    start_date: newProject.startDate,
    end_date: newProject.endDate,
    // members: newProject.members,
    contact_role: newProject.contactRole,
    project_type: newProject.projectType,
    salesaccount: newProject.salesAccount,
    responsible: newProject.responsible,
    accountable: newProject.accountable,
    consulted: newProject.consulted,
    informed: newProject.informed,
    description: newProject.description,
    status_id: newProject.statusId,
  };

  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/project`,
    payload
  );

  return toProject(response);
};

const updateProject = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  editedProject: EditedProject
): Promise<Project> => {
  const payload = {
    title: editedProject.title,
    customer_id: editedProject.customerId,
    external_reference: editedProject.externalReference,
    not_billable: editedProject.notBillable,
    budget: editedProject.budget,
    marginability_percentage: editedProject.marginabilityPercentage,
    tolerance_days: editedProject.toleranceDays,
    project_manager_id: editedProject.projectManagerId,
    category_id: editedProject.categoryId,
    status_id: editedProject.statusId,
    contact_name: editedProject.contactName,
    contact_surname: editedProject.contactSurname,
    contact_email: editedProject.contactEmail,
    start_date: editedProject.startDate,
    end_date: editedProject.endDate,
    // members: editedProject.members,
    contact_role: editedProject.contactRole,
    project_type: editedProject.projectType,
    salesaccount: editedProject.salesAccount,
    responsible: editedProject.responsible,
    accountable: editedProject.accountable,
    consulted: editedProject.consulted,
    informed: editedProject.informed,
    description: editedProject.description,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/project/${projectId}`,
    payload
  );

  return toProject(response);
};

const deleteProject = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"]
): Promise<Project["uid"]> => {
  const response = await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/project/${projectId}`
  );

  return response.uid;
};

const getProjectReport = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"]
): Promise<ProjectReport> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/project/${projectId}/report`
  );

  return response.map((item) => toProjectReport(item));
};

const fetchProjectExpenses = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"]
): Promise<Expense[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/project/${projectId}/expense`
  );

  return response.map((item) => toProjectExpense(item));
};

const fetchProjectStatsticsReport = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  userId: User["uid"]
): Promise<ProjectStatstics> => {
  const response = await makeApiRequest(
    "GET",
    `/user/${userId}/organization/${organizationId}/project/${projectId}/hours`
  );

  return toProjectStatstics(response);
};

const addProjectExpense = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  expense: EditedProjectExpense
): Promise<Expense> => {
  const payload = {
    organization_id: expense.organizationId,
    project_id: expense.projectId,
    is_final_balance: expense.isFinalBalance,
    supplier: expense.supplier,
    title: expense.title,
    description: expense.description,
    amount: expense.amount,
    is_billable: expense.isBillable,
  };

  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/project/${projectId}/expense`,
    payload
  );

  return toProjectExpense(response);
};

const updateProjectExpense = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  expense: EditedProjectExpense
): Promise<Expense> => {
  const payload = {
    is_final_balance: expense.isFinalBalance,
    supplier: expense.supplier,
    title: expense.title,
    description: expense.description,
    amount: expense.amount,
    is_billable: expense.isBillable,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/project/${projectId}/expense/${expense.uid}`,
    payload
  );

  return toProjectExpense(response);
};

const updateProjectExpenseBeta = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  expense: EditedProjectExpenseBeta
): Promise<Expense> => {
  const payload = {
    is_final_balance: expense.isFinalBalance,
    supplier: expense.supplier,
    title: expense.title,
    description: expense.description,
    amount: expense.amount,
    is_billable: expense.isBillable,
    estimated_amount: expense.estimated_amount,
    confirmed_amount: expense.confirmed_amount,
    transacation_date: expense.transacation_date,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/project/${projectId}/expense/${expense.uid}`,
    payload
  );

  return toProjectExpense(response);
};
const addProjectExpenseBeta = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  expense: EditedProjectExpenseBeta
): Promise<Expense> => {
  const payload = {
    organization_id: expense.organizationId,
    project_id: expense.projectId,
    is_final_balance: expense.isFinalBalance,
    supplier: expense.supplier,
    title: expense.title,
    description: expense.description,
    amount: expense.amount,
    is_billable: expense.isBillable,
    estimated_amount: expense.estimated_amount,
    confirmed_amount: expense.confirmed_amount,
    transacation_date: expense.transacation_date,
  };

  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/project/${projectId}/expense`,
    payload
  );

  return toProjectExpense(response);
};

const deleteProjectExpense = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  expenseId: Expense["uid"]
): Promise<boolean> => {
  const response = await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/project/${projectId}/expense/${expenseId}`
  );

  return true;
};

const fetchProjectNotes = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"]
): Promise<ProjectNote[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/project/${projectId}/note`
  );

  return response.map((item) => toProjectNote(item));
};

const addProjectNote = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  note: EditedProjectNote
): Promise<ProjectNote> => {
  const payload = {
    note: note.note,
  };

  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/project/${projectId}/note`,
    payload
  );

  return toProjectNote(response);
};

const updateProjectNote = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  note: EditedProjectNote
): Promise<ProjectNote> => {
  const payload = {
    note: note.note,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/project/${projectId}/note/${note.uid}`,
    payload
  );

  return toProjectNote(response);
};

const deleteProjectNote = async (
  organizationId: Organization["uid"],
  projectId: Project["uid"],
  noteId: ProjectNote["uid"]
): Promise<boolean> => {
  const response = await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/project/${projectId}/note/${noteId}`
  );

  return true;
};

const enableIntegration = async (
  userId: User["uid"],
  service: string,
  authToken: string,
  tokenType: string,
  metadata?: {}
) => {
  const payload = {
    user_id: userId,
    service,
    auth_token: authToken,
    token_type: tokenType,
    metadata: metadata || null,
  };
  const response = await makeApiRequest(
    "POST",
    `/user/${userId}/integration`,
    payload
  );

  return response;
};

const disableIntegration = async (userId: User["uid"], service: string) => {
  const response = await makeApiRequest(
    "DELETE",
    `/user/${userId}/integration/${service}`
  );

  return response;
};

const getPaymoTasks = async () => {
  const response = await makeApiRequest("GET", `connect/paymo/tasks`);

  return response;
};

const getJiraTasks = async () => {
  const response = await makeApiRequest("GET", `connect/jira/tasks`);
  return response;
};

const getTimesheet = async (
  organizationId: Organization["uid"],
  startDate: Moment,
  endDate: Moment,
  timesheetType: "byDay" | "byProject" | "byClient"
): Promise<TimesheetRowByDay[] | TimesheetRowByProject[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/report/timesheet?start_date=${startDate.format(
      "YYYY-MM-DD"
    )}&end_date=${endDate.format("YYYY-MM-DD")}&timesheet_type=${timesheetType}`
  );

  return response.map((row) =>
    timesheetType === "byDay"
      ? toTimesheetByDayRow(row)
      : toTimesheetByProjectRow(row)
  );
};

const getTeamReport = async (
  organizationId: Organization["uid"],
  startDate: Moment,
  endDate: Moment
): Promise<TeamReport> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/report/team?start_date=${startDate.format(
      "YYYY-MM-DD"
    )}&end_date=${endDate.format("YYYY-MM-DD")}`
  );

  return response.map((row) => toTeamReportRow(row));
};

const getTimer = async (userId: User["uid"]): Promise<Timer | null> => {
  const response = await makeApiRequest("GET", `/user/${userId}/timer`);

  return response.data ? toTimer(response.data) : null;
};

const createTimer = async (
  userId: User["uid"],
  timeBlockingEventId: TimeBlockingEvent["uid"],
  startTime: Timer["startTime"]
): Promise<Timer> => {
  const payload = {
    start_time: startTime,
    time_blocking_event_id: timeBlockingEventId,
  };
  const response = await makeApiRequest(
    "POST",
    `/user/${userId}/timer`,
    payload
  );

  return toTimer(response.data);
};

const deleteTimer = async (userId: User["uid"]) => {
  await makeApiRequest("DELETE", `/user/${userId}/timer`);
};

const updateRecurrence = async (
  userId: User["uid"],
  recurrence_id: string,
  data: any
) => {
  const payload = {
    every: data.every,
    frequency: data.frequency,
    until: data.until,
    repeat: data.repeat,
    day_of_weeks: data.dayOfWeeks,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/user/${userId}/recurrence/${recurrence_id}`,
    payload
  );

  return response;
};

const fetchTimeBlockingEventsNotAssigned = async (
  userId: User["uid"]
): Promise<TimeBlockingEvent[]> => {
  const response = await makeApiRequest(
    "GET",
    `/user/${userId}/time-blocking/not-assigned`
  );
  return response.map((item) => toEvent(item));
};

const fetchCustomers = async (
  organizationId: Organization["uid"]
): Promise<Customer[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/customer`
  );

  return response.map((item) => toCustomer(item));
};

const createCustomer = async (
  organizationId: Organization["uid"],
  nominative: Customer["nominative"],
  email: Customer["email"],
  fiscalCode: Customer["fiscalCode"],
  vatNumber: Customer["vatNumber"],
  externalId: Customer["externalId"]
): Promise<Customer> => {
  const payload = {
    nominative,
    email,
    fiscal_code: fiscalCode,
    vat_number: vatNumber,
    external_id: externalId,
  };

  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/customer`,
    payload
  );

  return toCustomer(response);
};

const updateCustomer = async (
  organizationId: Organization["uid"],
  customerId: Customer["uid"],
  nominative: Customer["nominative"],
  email: Customer["email"],
  fiscalCode: Customer["fiscalCode"],
  vatNumber: Customer["vatNumber"],
  externalId: Customer["externalId"]
): Promise<Customer> => {
  const payload = {
    nominative,
    email,
    fiscal_code: fiscalCode,
    vat_number: vatNumber,
    external_id: externalId,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/customer/${customerId}`,
    payload
  );

  return toCustomer(response);
};

const deleteCustomer = async (
  organizationId: Organization["uid"],
  customerId: Customer["uid"]
): Promise<Customer["uid"]> => {
  const response = await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/customer/${customerId}`
  );

  return response.uid;
};

const fetchUserInsights = async (
  organizationId: Organization["uid"],
  userId: User["uid"],
  startDate: Moment,
  endDate: Moment
): Promise<TeamReportRow> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/report/user/${userId}?start_date=${startDate.format(
      "YYYY-MM-DD"
    )}&end_date=${endDate.format("YYYY-MM-DD")}`
  );

  return toTeamReportRow(response);
};

const fetchTask = async (
  organizationId: Organization["uid"],
  taskId: Task["uid"]
): Promise<Task> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/task/${taskId}`
  );
  return toTask(response);
};
const fetchTasks = async (
  organizationId: Organization["uid"],
  projectId?: Project["uid"],
  userId?: User["uid"]
): Promise<Task[]> => {
  const conditions: string[] = [];

  if (projectId) {
    conditions.push(`project_id=${projectId}`);
  }

  if (userId) {
    conditions.push(`user_id=${userId}`);
  }

  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/task?${conditions.join("&")}`
  );
  return response.map((item) => toTask(item));
};

const addTask = async (
  organizationId: Organization["uid"],
  task: any
): Promise<Task> => {
  const payload = {
    name: task.name,
    description: task.description,
    due_date: task.dueDate || null,
    color: null, // TODO: drop column ad delete field
    user_id: task.userId,
    project_id: task.projectId,
    parent_id: task.parentId || null,
    priority: task.priority,
    category_id: task.categoryId || null,
  };
  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/task`,
    payload
  );

  return toTask(response);
};

const updateTask = async (
  organizationId: Organization["uid"],
  task: any
): Promise<Task> => {
  const payload = {
    name: task.name,
    description: task.description,
    due_date: task.dueDate || null,
    complete: !!task.complete,
    color: task.color,
    user_id: task.userId,
    project_id: task.projectId,
    priority: task.priority,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/task/${task.uid}`,
    payload
  );

  return toTask(response);
};

const deleteTask = async (
  organizationId: Organization["uid"],
  taskId: Task["uid"]
): Promise<boolean> => {
  await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/task/${taskId}`
  );

  return true;
};

const fetchTaskCategories = async (
  organizationId: Organization["uid"]
): Promise<TaskCategory[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/task-category`
  );

  return response.map((item) => toTaskCategory(item));
};

const createTaskCategory = async (
  organizationId: Organization["uid"],
  category: TaskCategory
): Promise<TaskCategory> => {
  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/task-category`,
    category
  );

  return toTaskCategory(response);
};

const updateTaskCategory = async (
  organizationId: Organization["uid"],
  category: TaskCategory
): Promise<TaskCategory> => {
  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/task-category/${category.uid}`,
    category
  );

  return toTaskCategory(response);
};

const deleteTaskCategory = async (
  organizationId: Organization["uid"],
  categoryId: TaskCategory["uid"]
): Promise<boolean> => {
  await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/task-category/${categoryId}`
  );

  return true;
};

const fetchChangelogData = async (): Promise<any> => {
  const response = await fetch(`${keys.API_BASE_URL}changelog`, {
    method: "GET",
  });
  if (response.ok) {
    const responseText = await response.text();
    return responseText;
  } else {
    throw new Error();
  }
};

const fetchProjectLinks = async (
  project_id: Project["uid"]
): Promise<ProjectLinks[]> => {
  const response = await makeApiRequest("GET", `/project/${project_id}/links`);

  return response.map((item) => toProjectLinks(item));
};

const addProjectLinks = async (
  project_id: Project["uid"],
  projectLinks: ProjectLinks
): Promise<ProjectLinks> => {
  const payload = {
    title: projectLinks.title,
    url: projectLinks.url,
  };

  const response = await makeApiRequest(
    "POST",
    `/project/${project_id}/links`,
    payload
  );

  return toProjectLinks(response);
};

const updateProjectLinks = async (
  project_id: Project["uid"],
  projectLinks: ProjectLinks
): Promise<ProjectLinks> => {
  const payload = {
    title: projectLinks.title,
    url: projectLinks.url,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/project/${project_id}/links/${projectLinks.linkId}`,
    payload
  );

  return toProjectLinks(response);
};

const deleteProjectLinks = async (
  project_id: Project["uid"],
  link_id: string
): Promise<boolean> => {
  await makeApiRequest("DELETE", `/project/${project_id}/links/${link_id}`);

  return true;
};

const fetchProjectUpdates = async (
  project_id: Project["uid"]
): Promise<ProjectUpdate[]> => {
  const response = await makeApiRequest(
    "GET",
    `/project/${project_id}/updates`
  );

  return response.map((item) => toProjectUpdates(item));
};

const addProjectUpdate = async (
  project_id: Project["uid"],
  projectUpdate: ProjectUpdate
): Promise<ProjectUpdate> => {
  const payload = {
    data: projectUpdate.data,
    status: projectUpdate.status,
    update: projectUpdate.data.update,
    priorities: projectUpdate.data.priorities,
    roadblocks: projectUpdate.data.roadblocks,
  };

  const response = await makeApiRequest(
    "POST",
    `/project/${project_id}/updates`,
    payload
  );

  return toProjectUpdates(response);
};

const updateProjectUpdate = async (
  project_id: Project["uid"],
  projectIUpdate: ProjectUpdate
): Promise<ProjectUpdate> => {
  const payload = {
    data: projectIUpdate.data,
    status: projectIUpdate.status,
    update: projectIUpdate.data.update,
    priorities: projectIUpdate.data.priorities,
    roadblocks: projectIUpdate.data.roadblocks,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/project/${project_id}/updates/${projectIUpdate.updateId}`,
    payload
  );

  return toProjectUpdates(response);
};

const deleteProjectUpdate = async (
  project_id: Project["uid"],
  update_id: string
): Promise<boolean> => {
  await makeApiRequest("DELETE", `/project/${project_id}/updates/${update_id}`);

  return true;
};

const fetchOverheads = async (
  organizationId: Organization["uid"]
): Promise<Overhead[]> => {
  const response = await makeApiRequest(
    "GET",
    `/organization/${organizationId}/overheads`
  );
  return response.map(toOverhead);
};

const addOverhead = async (
  organizationId: Organization["uid"],
  overhead: Partial<Overhead>
): Promise<Overhead> => {
  const payload = {
    amount: overhead.amount,
    from_date: overhead.fromDate,
    to_date: overhead.toDate,
  };
  const response = await makeApiRequest(
    "POST",
    `/organization/${organizationId}/overheads`,
    payload
  );

  return toOverhead(response);
};

const updateOverhead = async (
  organizationId: Organization["uid"],
  overheadId: Overhead["uid"],
  overhead: Partial<Overhead>
): Promise<Overhead> => {
  const payload = {
    amount: overhead.amount,
    from_date: overhead.fromDate,
    to_date: overhead.toDate,
  };

  const response = await makeApiRequest(
    "PATCH",
    `/organization/${organizationId}/overheads/${overheadId}`,
    payload
  );

  return toOverhead(response);
};

const deleteOverhead = async (
  organizationId: Organization["uid"],
  overheadId: Overhead["uid"]
): Promise<boolean> => {
  await makeApiRequest(
    "DELETE",
    `/organization/${organizationId}/overheads/${overheadId}`
  );

  return true;
};

const api = {
  setSession,
  clearSession,
  signUser,
  login,
  basicSignUp,
  getCurrentUser,
  lostPassword,
  changePassword,
  updateUser,
  fetchTimeBlockingEvents,
  addTimeBlockingEvent,
  setTimeBlockingEvent,
  deleteTimeBlockingEvent,
  getTodoistSignedUrl,
  getTodoistTasks,
  disableTodoist,
  fetchOrganization,
  createOrganization,
  updateOrganization,
  fetchOrganizationRoles,
  createOrganizationRole,
  updateOrganizationRole,
  fetchOrganizationUsers,
  updateOrganizationUser,
  inviteUser,
  fetchProjectCategories,
  createProjectCategory,
  updateProjectCategory,
  deleteProjectCategory,
  fetchProjects,
  fetchProject,
  fetchUserProjects,
  createProject,
  updateProject,
  deleteProject,
  getProjectReport,
  fetchProjectExpenses,
  addProjectExpense,
  updateProjectExpense,
  addProjectExpenseBeta,
  updateProjectExpenseBeta,
  deleteProjectExpense,
  fetchProjectNotes,
  addProjectNote,
  updateProjectNote,
  deleteProjectNote,
  enableIntegration,
  disableIntegration,
  getPaymoTasks,
  getJiraTasks,
  getTimesheet,
  getTimer,
  createTimer,
  deleteTimer,
  updateRecurrence,
  fetchTimeBlockingEventsNotAssigned,
  fetchCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  fetchUserInsights,
  fetchTask,
  fetchTasks,
  addTask,
  updateTask,
  deleteTask,
  getTeamReport,
  fetchTaskCategories,
  createTaskCategory,
  updateTaskCategory,
  deleteTaskCategory,
  fetchChangelogData,
  fetchProjectStatsticsReport,
  fetchProjectLinks,
  addProjectLinks,
  updateProjectLinks,
  deleteProjectLinks,
  fetchProjectUpdates,
  deleteProjectUpdate,
  updateProjectUpdate,
  addProjectUpdate,
  fetchOverheads,
  addOverhead,
  updateOverhead,
  deleteOverhead,
};
export * from "./plans";
export * from "./projectsWorkflows";
export * from "./asana";
export * from "./userContracts";

export default api;
