import { Badge, Heading, HStack, Stack } from "@chakra-ui/react";
import Project from "../../models/Project";
import { useTranslation } from "react-i18next";
import { Icon } from "../Icon";
import { getStatusColor } from "../../utils/project";
import { useCallback } from "react";

type Props = {
  project: Project;
  onClick: (projectId: Project["uid"]) => void;
};

export const ProjectCard: React.FC<Props> = ({ project, onClick }) => {
  const { t } = useTranslation();


  const BadgeComnponent = useCallback(() => {
    let statusColor = getStatusColor(project.status.name);
    let statusName = project.status.name
    if (project.projectUpdates.length) {
      statusName = project.projectUpdates[0].status || project.status.name
      statusColor = getStatusColor(project.projectUpdates[0].status || "") || getStatusColor(project.status.name);
    }

    return (<Badge
      backgroundColor={statusColor.bg}
      variant="outline"
      borderColor={statusColor.bc}
      borderRadius={"full"}
      borderWidth={"1px"}
      color={statusColor.bc}
      px={"3"}
      py={"1"}
      textTransform={"none"}
    >
      {statusName}
    </Badge>)
  }, [project.projectUpdates])

  return (
    <Stack
      border={"1px solid"}
      padding="1.5rem"
      borderRadius={"0.5rem"}
      borderColor={"#e3e3e3"}
      cursor={"pointer"}
      onClick={() => onClick(project.uid)}
      backgroundColor={"#FDFDFD"}
    >
      <HStack display={'flex'} justifyContent={"flex-end"}>
        <BadgeComnponent />
      </HStack>
      <HStack mb="0.5rem">
        <Icon iconName={"HiOutlineCube"} color={"black"} />{" "}
        <Heading as={"h6"} size={"md"} fontWeight={"semibold"}>
          {project.title}
        </Heading>
      </HStack>
      <div>
        <div>
          {t("components.projectCard.customer")}:{" "}
          {project.customer?.nominative}
        </div>
        <div>
          {t("components.projectCard.endDate")}:{" "}
          {project.endDate?.toLocaleDateString()}
        </div>
        {/* <div>
          {t("components.projectCard.currentMarginability")}:{" "}
          {project.currentMarginability} %
        </div> */}
      </div>
    </Stack>
  );
};
