import {
  Box,
  Button,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery
} from "@chakra-ui/react";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import styled from "styled-components";
import Project from "../../../../models/Project";
import { Action } from "../../../../screens/ProjectDetails";

interface FinalExpensesProps {
  currentProject: Project;
  expenseDispatch: React.Dispatch<Action>;
  t: TFunction;
}

const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
  }
`;

const ExpenseItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 0.875rem;
  color: #4B5563;
  margin-bottom: 0.25rem;
`;

const Value = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
`;

const FinalExpensesContainer = styled.section`
  padding: 0px 10px;

  @media (min-width: 768px) {
      padding: 0px 20px;
  }
`;

const ExpenseTable = styled.section`
  margin-top: 2rem;
  overflow-x: auto;
`;

// @ts-ignore
const StyledTr = styled(Tr) <{ isOdd: boolean }>`
  background-color: ${props => props.isOdd ? "#F9FAFB" : "white"};
  transition: background-color 0.3s ease;

  &:hover {
      background-color: ${props => props.isOdd ? "#E5E7EB" : "#F3F4F6"};
  }
`;

const StyledBox = styled(Box) <{ isOdd: boolean }>`
  background-color: ${props => props.isOdd ? "#F9FAFB" : "white"};
  transition: background-color 0.3s ease;

  &:hover {
      background-color: ${props => props.isOdd ? "#E5E7EB" : "#F3F4F6"};
  }
`;

const mockExpenses = [
  { id: 1, title: "Invoice #54/2024", description: "Cost of external collaborator working for 2 months on the project", estimatedAmount: "€345,00", confirmedAmount: "€360,00", transactionDate: "14/09/2024" },
  { id: 2, title: "Invoice 2345", description: "Cost to print brochure for the event", estimatedAmount: "€345,00", confirmedAmount: "€360,00", transactionDate: "14/09/2024" },
  { id: 3, title: "Invoice 5678", description: "Annual subscription to new marketing tool", estimatedAmount: "€345,00", confirmedAmount: "€360,00", transactionDate: "14/09/2024" },
  { id: 4, title: "Invoice 12345", description: "Cost of external collaborator working for 2 months on the project", estimatedAmount: "€345,00", confirmedAmount: "€360,00", transactionDate: "14/09/2024" },
  { id: 5, title: "Invoice 5678", description: "Annual subscription to new marketing tool", estimatedAmount: "€345,00", confirmedAmount: "€360,00", transactionDate: "14/09/2024" },
];

export const FinalExpenses = ({ currentProject, expenseDispatch, t }: FinalExpensesProps) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  console.log("expenses -> ", currentProject.expenses);

  useEffect(() => {
    if (currentProject?.fetchExpenses) {
      currentProject?.fetchExpenses();
    }
  }, [currentProject]);

  return (
    <FinalExpensesContainer>
      <Container>
        <Grid>
          <ExpenseItem
            label={t("Total estimated amount")}
            value={`€ ${currentProject?.expenses ? currentProject?.expenses.reduce((prev, cur) => {
              return prev += cur.estimated_amount ?
                typeof cur.estimated_amount === "string" ?
                  parseInt(cur.estimated_amount) :
                  cur.estimated_amount :
                0
            }, 0) : "--"}`}
          />
          <ExpenseItem
            label={t("Total confirmed amount")}
            value={`€ ${currentProject?.expenses ? currentProject?.expenses.reduce((prev, cur) => {
              return prev += cur.confirmed_amount ?
                typeof cur.confirmed_amount === "string" ?
                  parseInt(cur.confirmed_amount) :
                  cur.confirmed_amount :
                0
            }, 0) : "--"}`}
          />
          <ExpenseItem
            label={t("Total expenses")}
            value={`${currentProject?.expenses ? currentProject.expenses.length : 0}`}
          />
        </Grid>
      </Container>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button colorScheme="teal" variant="solid" type="submit"
          onClick={() => expenseDispatch({ type: "new-expense" })}
        >
          {t<string>("screens.expenses.addExpense")}
        </Button>
      </Box>

      <ExpenseTable>
        {isLargerThan768 ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color="gray.400">{t<string>('screens.projects.reports.title')}</Th>
                <Th color="gray.400">{t<string>('screens.projects.reports.discription')}</Th>
                <Th color="gray.400">{t<string>('screens.projects.reports.estimatedAmpunt')}</Th>
                <Th color="gray.400">{t<string>('screens.projects.reports.confirmedAmpunt')}</Th>
                <Th color="gray.400">{t<string>('screens.projects.reports.transactionDate')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentProject?.expenses.map((expense, index) => (
                <StyledTr key={expense.uid} isOdd={index % 2 !== 0} onClick={() => {
                  expenseDispatch({
                    type: "edit-expense",
                    payload: { expense },
                  })
                }}>
                  <Td>{expense.title}</Td>
                  <Td>{expense.description}</Td>
                  <Td>{expense.estimated_amount}</Td>
                  <Td>{expense.confirmed_amount}</Td>
                  <Td>{expense.transacation_date !== null ? new Date(expense.transacation_date as string).toISOString()?.split("T")[0] : "--"}</Td>
                  {/* <Td>
                                      <Menu>
                                          <MenuButton
                                              as={IconButton}
                                              aria-label='Options'
                                              icon={<BsThreeDotsVertical />}
                                              variant='ghost'
                                          />
                                          <MenuList>
                                              <MenuItem onClick={(e) => {
                                                  e.preventDefault();
                                                  expenseDispatch({
                                                      type: "edit-expense",
                                                      payload: { expense },
                                                  })
                                              }}>Edit</MenuItem>
                                          </MenuList>
                                      </Menu>
                                  </Td> */}
                </StyledTr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Stack spacing={4}>
            {currentProject?.expenses.map((expense, index) => (
              <StyledBox key={expense.uid} p={4} borderWidth={1} borderRadius="md" isOdd={index % 2 !== 0}
                onClick={() => {
                  expenseDispatch({
                    type: "edit-expense",
                    payload: { expense },
                  })
                }}
              >
                <Text fontWeight="bold">{expense.title}</Text>
                <Text fontSize="sm">{expense.description}</Text>
                <Stack direction="row" justifyContent="space-between" mt={2}>
                  <Text fontSize="sm">Estimated: {expense.estimated_amount}</Text>
                  <Text fontSize="sm">Confirmed: {expense.confirmed_amount}</Text>
                </Stack>
                <Text fontSize="sm" mt={1}>Date: {expense?.transacation_date !== null ? new Date(expense.transacation_date as string).toISOString()?.split("T")[0] : "--"}</Text>
                {/* <Box textAlign="right" mt={2}>
                                  <Menu>
                                      <MenuButton
                                          as={IconButton}
                                          aria-label='Options'
                                          icon={<BsThreeDotsVertical />}
                                          variant='ghost'
                                          size="sm"
                                      />
                                      <MenuList>
                                          <MenuItem onClick={(e) => {
                                              e.preventDefault();
                                              expenseDispatch({
                                                  type: "edit-expense",
                                                  payload: { expense },
                                              })
                                          }}>Edit</MenuItem>
                                      </MenuList>
                                  </Menu>
                              </Box> */}
              </StyledBox>
            ))}
          </Stack>
        )}
      </ExpenseTable>
    </FinalExpensesContainer>
  );
};

const ExpenseItem: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <ExpenseItemWrapper>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </ExpenseItemWrapper>
);