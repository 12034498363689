import React, { useEffect } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
    Input,
    InputGroup,
    InputLeftAddon,
    VStack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Button
} from "@chakra-ui/react";

import { Organization, EditedProjectExpense, EditedProjectExpenseBeta } from "../types";
import Project from "../models/Project";
import Expense from "../models/Expense";
import { ModalFooterForm } from "./ModalFooterForm";
import { useTranslation } from "react-i18next";
import { DatePicker } from "./DatePicker";

type ExpenseFormProps = {
    organizationId: Organization["uid"];
    projectId: Project["uid"];
    expense: Expense | null;
    onSubmit: (expense: any) => any;
    onCancel: () => any;
    onLoad?: () => any;
    onDelete?: (expense: Expense) => any;
};

export const ExpenseFormBeta: React.FC<ExpenseFormProps> = ({
    organizationId,
    projectId,
    expense,
    onSubmit,
    onCancel,
    onLoad,
    onDelete,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        onLoad && onLoad();
    }, [onLoad]);

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required(t("screens.expenses.validations.titleRequired"))
            .min(1, t("screens.expenses.validations.titleRequired")),
    });

    const initialValues: EditedProjectExpenseBeta = {
        uid: expense ? expense.uid : null,
        organizationId,
        projectId,
        isFinalBalance: expense ? expense.isFinalBalance : false,
        supplier: expense ? expense.supplier : "",
        title: expense ? expense.title : "",
        description: expense ? expense.description : "",
        amount: expense ? expense.amount : 0,
        isBillable: expense ? expense.isBillable : false,
        estimated_amount: expense ? expense.estimated_amount : 0,
        confirmed_amount: expense ? expense.confirmed_amount : 0,
        transacation_date: expense ? expense.transacation_date : null,
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
                values: EditedProjectExpenseBeta,
                { setSubmitting }: FormikHelpers<EditedProjectExpenseBeta>
            ) => {
                // alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
                if (!values.isFinalBalance) {
                    values.isBillable = false;
                }
                onSubmit(values);
            }}
        >
            {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                <Form>
                    {/* @ts-ignore */}
                    <VStack spacing={4} align="stretch">
                        <Field name="title">
                            {({ field }) => (
                                <FormControl isInvalid={!!(errors.title && touched.title)}>
                                    <FormLabel htmlFor="title">Title</FormLabel>
                                    <Input {...field} id="title" placeholder="Title" />
                                    <FormErrorMessage>{errors.title}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="description">
                            {({ field }) => (
                                <FormControl isInvalid={!!(errors.description && touched.description)}>
                                    <FormLabel htmlFor="description">Description</FormLabel>
                                    <Input {...field} id="description" placeholder="Description" />
                                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="estimated_amount">
                            {({ field }) => (
                                <FormControl isInvalid={!!(errors.estimated_amount && touched.estimated_amount)}>
                                    <FormLabel htmlFor="estimated_amount">Estimated amount</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon children="€" />
                                        <Input {...field} id="estimated_amount" type="number" placeholder="0.00" />
                                    </InputGroup>
                                    <FormErrorMessage>{errors.estimated_amount}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="confirmed_amount">
                            {({ field }) => (
                                <FormControl isInvalid={!!(errors.confirmed_amount && touched.confirmed_amount)}>
                                    <FormLabel htmlFor="confirmed_amount">Confirmed amount</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon children="€" />
                                        <Input {...field} id="confirmed_amount" type="number" placeholder="0.00" />
                                    </InputGroup>
                                    <FormErrorMessage>{errors.confirmed_amount}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="transacation_date">
                            {({ field }) => (
                                <FormControl isInvalid={!!(errors.transacation_date && touched.transacation_date)}>
                                    <FormLabel htmlFor="transacation_date">Transaction date</FormLabel>
                                    <DatePicker
                                        // @ts-ignore
                                        dateFormat={"dd/MM/yyyy"}
                                        selectedDate={
                                            values.transacation_date
                                                ? new Date(values.transacation_date)
                                                : undefined
                                        }
                                        onChange={(date) => {
                                            setFieldValue("transacation_date", date || null);
                                        }}
                                    />
                                    {/* <Input {...field} id="transacation_date" type="date" /> */}
                                    <FormErrorMessage>{errors.transacation_date}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                    </VStack>
                    <ModalFooterForm
                        onCancel={onCancel}
                        onDelete={
                            !!onDelete && expense
                                ? () => {
                                    onDelete(expense);
                                }
                                : undefined
                        }
                    />
                </Form>
            )}
        </Formik>
    );
};