import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { ProjectUpdate } from "../../../../types";
import { ModalFooterForm } from "../../../ModalFooterForm";
// import RichTextComponent from "../../../RichTextComponent";
import { useTranslation } from "react-i18next";

interface ProjectUpdateFormValues {
  updateId: string | null;
  status: string;
  update: string;
  priorities: string;
  roadblocks: string;
  date: Date | null;
}

interface ProjectUpdateFormProps {
  isOpen: boolean;
  onClose: () => void;
  pUpdate: ProjectUpdate | null;
  onDelete: (update: ProjectUpdate) => void;
  onSubmit: (update: ProjectUpdate) => void;
}

const ProjectUpdateForm: React.FC<ProjectUpdateFormProps> = ({
  isOpen,
  onClose,
  pUpdate,
  onDelete,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const initialValues: ProjectUpdateFormValues = {
    updateId: pUpdate?.updateId ? pUpdate?.updateId : null,
    status: pUpdate?.status ? pUpdate?.status : "In progress",
    update: pUpdate?.data.update ? pUpdate?.data.update : "",
    priorities: pUpdate?.data.priorities ? pUpdate?.data.priorities : "",
    roadblocks: pUpdate?.data.roadblocks ? pUpdate?.data.roadblocks : "",
    date: pUpdate?.date ? pUpdate?.date : null,
  };

  const handleSubmit = (values: ProjectUpdateFormValues) => {
    console.log(values);
    // alert(JSON.stringify(values, null, 2))
    const updateValue: ProjectUpdate = {
      status: values.status,
      data: {
        update: values.update,
        priorities: values.priorities,
        roadblocks: values.roadblocks,
      },
      updateId: values.updateId ? values.updateId : null,
      date: values.date ? values.date : null,
    };
    console.log(" update -> value -> \n", updateValue);
    onSubmit(updateValue);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      {/* @ts-ignore */}
      <ModalContent overflowY={"scroll"} maxHeight="80%">
        <ModalHeader borderBottomWidth="1px">
          {pUpdate?.updateId
            ? t("screens.projects.projectUpdate.titleEdit")
            : t("screens.projects.projectUpdate.title")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Field type={"hidden"} name={"updateId"} />
              <Field type={"hidden"} name={"date"} />
              <ModalBody>
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Field as={Select} name="status">
                      <option value="Halted">Progress halted</option>
                      <option value="Potential risk">Potential risk</option>
                      <option value="In progress">In progress</option>
                      <option value="Delivered">Delivered</option>
                    </Field>
                  </FormControl>
                  <Field id={"update"} name={"update"}>
                    {({ field, form: { setFieldValue }, meta }) => (
                      <FormControl>
                        <FormLabel>Update</FormLabel>
                        <Textarea
                          rows={4}
                          name={field.name}
                          value={field.value}
                          onChange={(e) =>
                            setFieldValue(field.name, e.target.value)
                          }
                        />
                      </FormControl>
                    )}
                  </Field>

                  <Field id={"priorities"} name={"priorities"}>
                    {({ field, form: { setFieldValue }, meta }) => (
                      <FormControl>
                        <FormLabel>Priorities</FormLabel>
                        <Textarea
                          rows={4}
                          name={field.name}
                          value={field.value}
                          onChange={(e) =>
                            setFieldValue(field.name, e.target.value)
                          }
                        />
                      </FormControl>
                    )}
                  </Field>

                  <Field id={"roadblocks"} name={"roadblocks"}>
                    {({ field, form: { setFieldValue }, meta }) => (
                      <FormControl>
                        <FormLabel>Roadblocks</FormLabel>
                        <Textarea
                          rows={4}
                          name={field.name}
                          value={field.value}
                          onChange={(e) =>
                            setFieldValue(field.name, e.target.value)
                          }
                        />
                      </FormControl>
                    )}
                  </Field>
                </VStack>
              </ModalBody>
              <Box px="24px">
                <ModalFooterForm
                  onCancel={onClose}
                  onDelete={
                    onDelete && pUpdate ? () => onDelete(pUpdate) : undefined
                  }
                />
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ProjectUpdateForm;
