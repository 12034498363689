import { SessionStore } from "./stores/Session";
import { BoardStore } from "./stores/Board";
import { OrganizationStore } from "./stores/Organization";
import Project from "./models/Project";
import { Ability } from "@casl/ability";
import { UiStore } from "./stores/UI";

export type StorageKey = "session" | "currentUser";

export type Stores = {
  session: SessionStore;
  board: BoardStore;
  organization: OrganizationStore;
  ui: UiStore;
};

export type FixMeLater = any;

export type TimeBlockingEventType = "planning" | "task" | "activity";

export type TimeBlockingEvent = {
  uid: string;
  title: string;
  startTime: number;
  endTime: number;
  userId: string;
  color?: string;
  elapsedTime: number;
  source?: string;
  gEventId?: string;
  calanderId?: string | null;
  sourceId?: string;
  completed: boolean;
  eventType: TimeBlockingEventType;
  projectId: Project["uid"] | null;
  project: {
    uid: Project["uid"];
    title: Project["title"];
  } | null;
  recurrenceId: string | null;
  recurrence: RecurrenceRule | null;
  category: TaskCategory | null;
  categoryId?: TaskCategory["uid"];
  taskLinkId?: Task["uid"] | null;
};

export type GoogleCalendarEvent = {
  id: string;
  title: string;
  htmlLink: string;
  startTime: number;
  endTime: number;
  status: string;
  eventType?: string;
};

export enum FullCalendarViews {
  TIME_GRID_WEEK = "timeGridWeek",
  TIME_GRID_DAY = "timeGridDay",
}

export type UserPreferences = {
  allDaySlot: boolean;
  weekends: boolean;
  slotDuration: string;
  slotMinTime: string;
  slotMaxTime: string;
  businessDaysOfWeek: number[];
  businessStartTime: string;
  businessEndTime: string;
  gcalendars: string[];
  calendarView?: FullCalendarViews;
  totalHours?: number; ////seconds
};

export type ServiceCode =
  | "todoist"
  | "paymo"
  | "jira"
  | "gcalendar"
  | "timetrap"
  | "asana";

type Integration = {
  service: ServiceCode;
};

export type PlanUnitType = {
  unitType: string;
  tierLimit?: number;
  tierLimited?: boolean;
  enabled?: boolean;
};

export enum PlanNames {
  FREE = "free",
  UNLIMITED = "unlimited",
}

export type Subscription = {
  id: number;
  billingType: string;
  billingPeriod: number;
  initialPrice: {
    USD: string;
    EUR: string;
  };
  recurringPrice: {
    USD: string;
    EUR: string;
  };
  trialDays: number;
};

export type Plan = {
  uid: string;
  name: string;
  config: PlanUnitType[];
  subscription: Subscription | null;
};

export type Organization = {
  uid: string;
  name: PlanNames;
  plan: Plan;
  subscriptionId: string | null;
  domains?: string[];
};

// TODO : Remove
export type ACLSRules = {
  organization: {
    read: boolean;
    edit: boolean;
  };
  users: {
    read: boolean;
    edit: boolean;
    create: boolean;
  };
  project: {
    read: boolean;
    edit: boolean;
    create: boolean;
  };
};

export type ACLSubject = "organization" | "users" | "project";

export type ACLAction = "read" | "edit" | "create";
// TODO : ... Remove

export type RawAbility = {
  subject: string | string[];
  action: string[];
};

export type Role = {
  uid: string;
  name: string;
  acls: RawAbility[];
};

export type UserRole = {
  uid: string;
  name: string;
  acls: Ability;
};

export type User = {
  email: string;
  imageUrl?: string;
  nominative: string;
  uid: string;
  status: string;
  preferences: UserPreferences;
  integrations: Integration[];
  organization: Organization | null;
  role: UserRole | null;
  hourlyCost: number;
  contractualHours: number;
  productivityTarget: number | null;
  termsOfUse: boolean;
  privacyPolicy: boolean;
  cookieConsent: boolean;
  externalId: string | null;
  language: string;
};

export type TeamMember = Pick<
  User,
  "uid" | "email" | "nominative" | "imageUrl"
> & {
  userTags: string[];
};

export type InvitedUser = Pick<User, "email" | "nominative"> & {
  roleId: Role["uid"];
};

export type UserInsights = {
  targetHours: number | null;
  workLogged: number | null;
};

export type OrganizationRole = {
  organization: Organization;
  role: Role;
};

export type ProjectCategory = {
  uid: string;
  name: string;
  color?: string | null;
};

export type EditedProject = {
  uid: Project["uid"] | null;
  title: Project["title"];
  customerId: Customer["uid"] | null;
  externalReference: Project["externalReference"];
  notBillable: Project["notBillable"];
  budget: Project["budget"];
  marginabilityPercentage: Project["marginabilityPercentage"];
  toleranceDays: Project["toleranceDays"];
  projectManagerId: User["uid"];
  categoryId: ProjectCategory["uid"] | null;
  statusId: ProjectStatus["uid"] | null;
  contactName: string | null;
  contactSurname: string | null;
  contactEmail: string | null;
  contactRole: string | null;
  startDate: Project["startDate"];
  endDate: Project["endDate"];
  closingDate: Project["closingDate"];
  // members: string[];
  salesAccount: string[];
  responsible: string[];
  accountable: string[];
  consulted: string[];
  informed: string[];
  description: string | null;
  projectType: string | null;
};

export type ProjectReport = {
  title: TimeBlockingEvent["title"];
  startTime: TimeBlockingEvent["startTime"];
  endTime: TimeBlockingEvent["endTime"];
  elapsedTime: TimeBlockingEvent["elapsedTime"];
  user: User;
  linkTask: {
    name: Task["name"];
    uid: Task["uid"];
  };
  category: TaskCategory | null;
  project?: { uid: string; title: string };
};

export type UserProjectLinksTags = {
  responsible: string[] | null;
  accountable: string[] | null;
  consulted: string[] | null;
  informed: string[] | null;
};

export type ExpenseType = {
  uid: string;
  code: string;
};

export type EditedProjectExpense = {
  uid: string | null;
  organizationId: Organization["uid"];
  projectId: Project["uid"];
  isFinalBalance: boolean;
  supplier: string | null;
  title: string;
  description: string | null;
  amount: number | null;
  isBillable: boolean;
};

// for the newr verison of expense CURD
export type EditedProjectExpenseBeta = {
  uid: string | null;
  organizationId: Organization["uid"];
  projectId: Project["uid"];
  isFinalBalance: boolean;
  supplier: string | null;
  title: string;
  description: string | null;
  amount: number | null;
  estimated_amount: number | null;
  confirmed_amount: number | null;
  transacation_date: Date | string | null;
  isBillable: boolean;
};

export type ProjectNote = {
  uid: string;
  note: string;
  createdAt: string;
  createdBy: User;
};

export type EditedProjectNote = {
  uid: string | null;
  note: string;
};

type TimeSheetRow = {
  userId: User["uid"];
  nominative: User["nominative"];
  workLogDate: Date;
  workLog: number; // time in seconds
};
export type TimesheetRowByDay = TimeSheetRow & {
  contractualHours: number;
  contractualHoursByDay: { [day: number]: string } | null;
  contractType: "full-time" | "part-time";
};
export type TimesheetRowByProject = TimeSheetRow & {
  projectTitle: string;
  projectId: Project["uid"];
  customerNominative: string;
};
export type TimesheetRowByClient = TimeSheetRow & {
  customerNominative: string;
};
export type Timesheet =
  | TimesheetRowByDay[]
  | TimesheetRowByProject[]
  | TimesheetRowByClient[];

export type TeamReportRow = {
  userId: User["uid"];
  nominative: User["nominative"];
  imageUrl: User["imageUrl"];
  contractualHours: number;
  plannedTime: number; // time in seconds
  workLog: number; // time in seconds
  billableWorkLog: number; // time in seconds
  taskCategoriesReport: {
    categoryName: string;
    color: string;
    elapsedTime: number;
  }[];
};

export type TeamReport = TeamReportRow[];

export type RecurrenceRule = {
  every: number;
  frequency: "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";
  until: Date | null;
  repeat: number | null;
  dayOfWeeks: number[];
};

export type Customer = {
  uid: string;
  nominative?: string;
  email?: string;
  fiscalCode?: string;
  vatNumber?: string;
  externalId?: string;
};

export type TaskPriority = 10 | 20 | 30 | 40 | 50;

export type Task = {
  uid: string;
  name: string;
  description?: string;
  dueDate: number | null;
  color?: string;
  complete: boolean;
  user: User;
  project?: { uid: string; title: string };
  organizationId: string;
  parentTask?: Task;
  subTasks?: Task[];
  priority: TaskPriority;
  category: TaskCategory | null;
  activityHours?: number;
};

export type TaskCategory = {
  uid: string;
  name: string;
  color?: string | null;
  isUsedForTasks: boolean | null;
  isUsedForPlanning: boolean | null;
};

export type Period = {
  fromDate: Date;
  toDate: Date;
};

export enum DeleteRecurrenceActions {
  ONLY_CURRENT_EVENT = "ONLY_CURRENT_EVENT",
  ALL_RECURRENT_EVENTS = "ALL_RECURRENT_EVENTS",
}

export type AsanaWorkspace = {
  gid: string;
  name: string;
  resource_type: string;
};

export type AsanaProject = {
  gid: string;
  name: string;
  resource_type: string;
};

export type ProjectStatus = {
  uid: string;
  key: string;
  name: string;
  editable: boolean;
  selectedByDefault: boolean;
};

export type Webhook = {
  uid: string;
  url: string;
  description: string;
  events: string[];
  enabled: boolean;
  conditions: { [event: string]: string | string[] };
};

export type UserContract = {
  uid: string;
  hourlyCost: number | null;
  contractType: "full-time" | "part-time";
  contractualHours: number | null;
  contractualHoursByDay: { [day: number]: string } | null;
  productivityTarget: number | null;
  fromDate: Date;
  toDate: Date;
};

export type ProjectStatstics = {
  budget: number;
  availableBudget: number;
  targetBudget: number;
  targetMarginabilityPercentage: number;
  currentMarginalityPercentage: number;
  totalExpenses: number;
  totalConfirmedHoursCost: number;
  totalConfirmedHours: number;
  totalUnconfirmedHoursCost: number;
  totalUnconfirmedHours: number;
  totalPlannedHoursCost: number;
  totalPlannedHours: number;
  cumulativeTotalCosts: {
    date: string;
    total_cost: number;
  }[];
  costsForecast: {
    date: string;
    total_cost: number;
  }[];
};

export const userTags = ["responsible", "accountable", "consulted", "informed"];
export const userTags2 = {
  responsible: "responsible",
  accountable: "accountable",
  consulted: "consulted",
  informed: "informed",
};

export type ChartDataValueT = {
  date: string;
  value: number;
};

export type ProjectLinks = {
  linkId?: string | null;
  title: string | undefined;
  url: string | undefined;
};

export type ProjectUpdate = {
  updateId: string | null;
  status: string | undefined;
  date: Date | null;
  data: {
    update: string | null;
    priorities: string | null;
    roadblocks: string | null;
  };
};

export type Overhead = {
  uid: string;
  amount: number;
  fromDate: Date;
  toDate: Date;
};
