import { Box, SimpleGrid } from "@chakra-ui/react";
import { TFunction } from "i18next";
import Project from "../../../models/Project";
import { Avatar } from "../../Avatar";
import { GridCell } from "../GridCell/GridCell";
import { useEffect, useMemo } from "react";

type HeaderGridProps = {
  currentProject: Project;
  t: TFunction;
};

export const HeaderGrid: React.FC<HeaderGridProps> = ({
  currentProject,
  t,
}) => {
  const projectsalesPerson = useMemo(() => {
    return (
      currentProject.member?.filter((e) => e.userTags.includes("sales")) || []
    );
  }, [currentProject, currentProject.member]);

  return (
    <Box as="section" display={"flex"} pl={"1rem"} mb={"10px"}>
      {/* @ts-ignore */}
      <SimpleGrid
        columns={4}
        row={2}
        spacing={4}
        // rowGap={"30px"} columnGap={"10px"}
        flex={0.85}
      >
        <GridCell
          label={t<string>("screens.projects.customer")}
          value={currentProject.customer?.nominative || ""}
        // helpText={"Name Surname"}
        />
        <GridCell
          label={t<string>("screens.projects.status")}
          value={currentProject.status?.name || ""}
        />
        <GridCell
          label={"Type"}
          value={
            currentProject.notBillable
              ? t<string>("screens.projects.notBillable")
              : t<string>("screens.projects.billable") || ""
          }
        />
        <GridCell
          label={t<string>("screens.projects.externalReference")}
          value={currentProject.externalReference || "--"}
        />
        <GridCell
          label={t<string>("screens.projects.startDate")}
          value={
            currentProject.startDate
              ? currentProject.startDate.toDateString()
              : "--"
          }
        />
        <GridCell
          label={t<string>("screens.projects.endDate")}
          value={
            currentProject.endDate
              ? currentProject.endDate.toDateString()
              : "--"
          }
        />
        <GridCell
          label={t<string>("screens.projects.projectManager")}
          value={
            currentProject.projectManager ? (
              <Avatar
                src={currentProject.projectManager.imageUrl}
                values={[currentProject.projectManager.nominative]}
                alt={currentProject.projectManager.nominative}
              />
            ) : (
              "--"
            )
          }
        />

        {(projectsalesPerson || []).length > 0 ? (
          <GridCell
            label={t<string>("screens.projects.salesManager")}
            value={
              projectsalesPerson
                ? projectsalesPerson.map((e) => {
                  return (
                    <Avatar
                      src={e.imageUrl}
                      values={[e.nominative]}
                      alt={e.nominative}
                    />
                  );
                })
                : "--"
            }
          />
        ) : (
          <div />
        )}
        {/* <GridCell
          label={t<string>("screens.projects.team")}
          value={currentProject.members && currentProject.members.length ? currentProject.members.map((member) => (
            <Avatar
              src={member.imageUrl}
              values={[member.nominative]}
              alt={member.nominative}
              mx={"1px"}
            />
          )) : "--"}
        /> */}
      </SimpleGrid>
      {/* <SimpleGrid
        columns={currentProject.startDate && currentProject.endDate ? 5 : 3}
        spacing={10}
        style={{ margin: "0rem 1rem" }}
      >
        <GridCell
          label={t<string>("screens.projects.customer")}
          value={currentProject.customer?.nominative || ""}
          helpText={
            currentProject.contactName && currentProject.contactSurname
              ? `${currentProject.contactName} ${currentProject.contactSurname}`
              : ""
          }
        />

        <GridCell
          label={t<string>("screens.projects.status")}
          value={currentProject.status?.name || ""}
        />

        {currentProject.externalReference && (
          <GridCell
            label={t<string>("screens.projects.externalReference")}
            value={currentProject.externalReference}
          />
        )}

        {currentProject.startDate && (
          <GridCell
            label={t<string>("screens.projects.startDate")}
            value={currentProject.startDate.toDateString()}
          />
        )}

        {currentProject.endDate && (
          <GridCell
            label={t<string>("screens.projects.endDate")}
            value={currentProject.endDate.toDateString()}
          />
        )}

        {currentProject.closingDate && (
          <GridCell
            label={t<string>("screens.projects.closingDate")}
            value={currentProject.closingDate.toDateString()}
          />
        )}
      </SimpleGrid>
      <SimpleGrid style={{ margin: "0.5rem 1rem" }} columns={2}>
        {currentProject.projectManager && (
          <GridCell
            label={t<string>("screens.projects.projectManager")}
            value={
              <Avatar
                src={currentProject.projectManager.imageUrl}
                values={[currentProject.projectManager.nominative]}
                alt={currentProject.projectManager.nominative}
              />
            }
          />
        )}

        {currentProject.members && currentProject.members.length > 0 && (
          <GridCell
            label={t<string>("screens.projects.team")}
            value={currentProject.members.map((member) => (
              <Avatar
                src={member.imageUrl}
                values={[member.nominative]}
                alt={member.nominative}
              />
            ))}
          />
        )}
      </SimpleGrid> */}
    </Box>
  );
};
