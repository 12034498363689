import React, { useEffect, useMemo } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import {
  ProjectCategory,
  User,
  EditedProject,
  Customer,
  ProjectStatus,
} from "../types";
import Project from "../models/Project";
import { ModalFooterForm } from "./ModalFooterForm";
import * as Yup from "yup";
import { HStack, Stack } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/input";
import { Select } from "./Select";
import { Checkbox } from "@chakra-ui/checkbox";
import { NumberInput, NumberInputField } from "@chakra-ui/number-input";
import { DatePicker } from "./DatePicker";
import styled from "styled-components";
import { Button, InputRightAddon, InputRightElement } from "@chakra-ui/react";
import ds from "../config/projects";

type ProjectFormProps = {
  project: Project | null;
  users: User[];
  categories: ProjectCategory[];
  customers: Customer[];
  isFetchingUsers: boolean;
  isFetchingCategories: boolean;
  isFetchingCustomers: boolean;
  isFetchingProjectsWorkflows: boolean;
  projectsWorkflows: ProjectStatus[];
  onSubmit: (project: EditedProject) => any;
  onCancel: () => any;
  onDelete?: (projectId: Project["uid"]) => any;
  onLoad?: () => any;
  onCreateCustomer: () => any;
};

type UserTags = {
  responsible: string[];
  accountable: string[];
  consulted: string[];
  informed: string[];
};

export const ProjectForm: React.FC<ProjectFormProps> = ({
  project,
  users,
  categories,
  customers,
  isFetchingUsers,
  isFetchingCategories,
  isFetchingCustomers,
  isFetchingProjectsWorkflows,
  projectsWorkflows,
  onSubmit,
  onCancel,
  onDelete,
  onLoad,
  onCreateCustomer,
}) => {
  const { t } = useTranslation();

  const customersOptions = useMemo(
    () =>
      customers.map((customer) => ({
        label: customer.nominative || "",
        value: customer.uid,
      })),
    [customers]
  );

  const usersOptions = useMemo(
    () =>
      users.filter(e => e.status === 'active').map((user) => ({
        label: user.nominative,
        value: user.uid,
      })),
    [users]
  );

  const categoriesOptions = useMemo(() => {
    const options = categories.map((category) => ({
      label: category.name,
      value: category.uid,
    }));
    options.push({ label: "Nessuna categoria", value: "" });
    return options;
  }, [categories]);

  const statusOptions = useMemo(
    () =>
      projectsWorkflows.map((status) => ({
        label: status.name,
        value: status.uid,
      })),
    [projectsWorkflows]
  );

  const projectTypeOptions = useMemo(
    () =>
      ds.project_type.map((e, ind) => {
        return {
          label: t(`screens.projects.projectTypes.${ind}`),
          value: e,
        };
      }),
    [ds]
  );

  useEffect(() => {
    onLoad && onLoad();
  }, []);

  const getSelectedCustomer: any = (customerId) => {
    if (!customerId) return "";

    const customer = customers.find((customer) => customer.uid === customerId);

    return customer
      ? {
        value: customer.uid,
        label: customer?.nominative || "",
      }
      : "";
  };

  const getSelectedUser: any = (userId) => {
    if (!userId) return "";

    const user = users.find((user) => user.uid === userId);

    return user
      ? {
        value: user.uid,
        label: user.nominative,
      }
      : "";
  };

  const getSelectedMembers: any = (members: User["uid"][]) => {
    if (!members) return [];

    const selectedMembers = users
      .filter((user) => members.indexOf(user.uid) > -1)
      .map((user) => ({
        value: user.uid,
        label: user.nominative,
      }));

    return selectedMembers;
  };

  const getSelectedCategory: any = (categoryId) => {
    if (!categoryId || (categoryId && categoryId.length === 0))
      return { label: "Nessuna categoria", value: "" };

    const category = categories.find((category) => category.uid === categoryId);

    return category
      ? {
        value: category.uid,
        label: category.name,
      }
      : { label: "Nessuna categoria", value: "" };
  };

  const getSelectedStatus: any = (statusId: ProjectStatus["uid"] | null) => {
    if (!statusId) {
      return null;
    }
    const status = projectsWorkflows.find(
      (workflow) => workflow.uid === statusId
    );

    return {
      value: status?.uid,
      label: status?.name,
    };
  };

  const getSelectedProjectTyps: any = (pType: string | null) => {
    if (!pType) {
      return null;
    }
    const index = ds.project_type.findIndex(
      (workflow) => workflow === pType
    );
    if (index > -1) {
      return {
        value: ds.project_type[index],
        label: t(`screens.projects.projectTypes.${index}`),
      };
    } else {
      return null
    }
  };

  console.log(project);

  return (
    <Formik
      initialValues={{
        uid: project?.uid || null,
        title: project?.title || "",
        customerId: project?.customer?.uid || null,
        externalReference: project?.externalReference || null,
        notBillable: project?.notBillable || false,
        budget: project?.budget || null,
        marginabilityPercentage: project?.marginabilityPercentage || null,
        toleranceDays: project?.toleranceDays || null,
        projectManagerId: project?.projectManager?.uid || "",
        categoryId: project?.category?.uid || null,
        statusId:
          project?.status?.uid ||
          projectsWorkflows.find((status) => status.key === "open")?.uid ||
          null,
        contactName: project?.contactName || null,
        contactSurname: project?.contactSurname || null,
        contactEmail: project?.contactEmail || null,
        contactRole: project?.contactRole || null,
        startDate: project?.startDate || null,
        endDate: project?.endDate || null,
        // members: project?.members?.map((member) => member.uid) || [],
        description: project?.description || null,
        closingDate: project?.closingDate || null,
        salesAccount: project?.member
          ? project?.member
            ?.filter((e) => e.userTags.includes("sales"))
            .map((e) => e.uid)
          : [] || [],
        responsible: project?.member
          ? project?.member
            ?.filter((e) => e.userTags.includes("responsible"))
            .map((e) => e.uid)
          : [] || [],
        accountable: project?.member
          ? project?.member
            ?.filter((e) => e.userTags.includes("accountable"))
            .map((e) => e.uid)
          : [] || [],
        consulted: project?.member
          ? project?.member
            ?.filter((e) => e.userTags.includes("consulted"))
            .map((e) => e.uid)
          : [] || [],
        informed: project?.member
          ? project?.member
            ?.filter((e) => e.userTags.includes("informed"))
            .map((e) => e.uid)
          : [] || [],
        projectType: project?.projectType || null,
        // responsible: [""],
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .required(t("screens.projects.validations.titleRequired"))
          .min(1, t("screens.projects.validations.titleRequired")),
        customerId: Yup.string()
          .required(t("screens.projects.validations.customerRequired"))
          .min(1, t("screens.projects.validations.customerRequired")),
        projectManagerId: Yup.string()
          .required(t("screens.projects.validations.projectManagerRequired"))
          .min(1, t("screens.projects.validations.projectManagerRequired")),
      })}
      onSubmit={(values: any, { setSubmitting }: FormikHelpers<any>) => {
        const categoryId =
          values.categoryId && values.categoryId?.length > 0
            ? values.categoryId
            : null;
        setSubmitting(false);
        onSubmit({ ...values, categoryId });
      }}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Field type={"hidden"} name={"uid"} />

          <HStack style={{ alignItems: "flex-start" }}>
            <Stack width={"50%"}>
              <Field id={"title"} name={"title"}>
                {({ field, form: { setFieldValue }, meta }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"title"}>
                      {t<string>("screens.projects.title")}
                    </StyledFormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      {...field}
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={"customerId"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"customerId"}>
                      {t<string>("screens.projects.customer")}
                    </StyledFormLabel>
                    {/* <HStack> */}
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Select
                        options={customersOptions}
                        value={getSelectedCustomer(field.value)}
                        isLoading={isFetchingCustomers}
                        onChange={(option) =>
                          setFieldValue(field.name, option?.value || null)
                        }
                        onBlur={field.onBlur}
                        isFlex={true}
                      />
                      <Button
                        size="sm"
                        h="-moz-max-content"
                        marginLeft={3}
                        onClick={onCreateCustomer}
                      >
                        {t<string>("screens.customers.actions.new")}
                      </Button>
                    </div>

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <HStack>
                <Field id={"contactName"} name={"contactName"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"contactName"}>
                        {t<string>("screens.projects.contactName")}
                      </StyledFormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field id={"contactSurname"} name={"contactSurname"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"contactSurname"}>
                        {t<string>("screens.projects.contactSurname")}
                      </StyledFormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field id={"contactEmail"} name={"contactEmail"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"contactEmail"}>
                        {t<string>("screens.projects.contactEmail")}
                      </StyledFormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <HStack>
                <Field id={"contactRole"} name={"contactRole"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"contactRole"}>
                        {t<string>("screens.projects.cotnactRole")}
                      </StyledFormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <Field name={"projectManagerId"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"projectManagerId"}>
                      {t<string>("screens.projects.projectManager")}
                    </StyledFormLabel>
                    <Select
                      options={usersOptions}
                      value={getSelectedUser(field.value)}
                      isLoading={isFetchingUsers}
                      onChange={(option) =>
                        setFieldValue(field.name, option?.value || null)
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {/* <Field name={"members"} >
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"members"}>
                      {t<string>("screens.projects.team")}
                    </StyledFormLabel>
                    <Select
                      options={usersOptions}
                      // @ts-ignore
                      isMulti
                      value={getSelectedMembers(field.value)}
                      isLoading={isFetchingUsers}
                      onChange={(option) =>
                        setFieldValue(
                          field.name,
                          // @ts-ignore
                          option?.map(
                            (selectedOption) => selectedOption.value
                          ) || null
                        )
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field> */}
              <Field name={"salesAccount"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"salesAccount"}>
                      {"Sales"}
                    </StyledFormLabel>
                    <Select
                      options={usersOptions}
                      // @ts-ignore
                      isMulti
                      value={getSelectedMembers(field.value)}
                      isLoading={isFetchingUsers}
                      onChange={(option) =>
                        setFieldValue(
                          field.name,
                          // @ts-ignore
                          option?.map(
                            (selectedOption) => selectedOption.value
                          ) || null
                        )
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"responsible"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"responsible"}>
                      {"Responsible"}
                    </StyledFormLabel>
                    <Select
                      options={usersOptions}
                      // @ts-ignore
                      isMulti
                      value={getSelectedMembers(field.value)}
                      isLoading={isFetchingUsers}
                      onChange={(option) =>
                        setFieldValue(
                          field.name,
                          // @ts-ignore
                          option?.map(
                            (selectedOption) => selectedOption.value
                          ) || null
                        )
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"accountable"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"accountable"}>
                      {"Accountable"}
                    </StyledFormLabel>
                    <Select
                      options={usersOptions}
                      // @ts-ignore
                      isMulti
                      value={getSelectedMembers(field.value)}
                      isLoading={isFetchingUsers}
                      onChange={(option) =>
                        setFieldValue(
                          field.name,
                          // @ts-ignore
                          option?.map(
                            (selectedOption) => selectedOption.value
                          ) || null
                        )
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={"consulted"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"members"}>
                      {"Consulted"}
                    </StyledFormLabel>
                    <Select
                      options={usersOptions}
                      // @ts-ignore
                      isMulti
                      value={getSelectedMembers(field.value)}
                      isLoading={isFetchingUsers}
                      onChange={(option) =>
                        setFieldValue(
                          field.name,
                          // @ts-ignore
                          option?.map(
                            (selectedOption) => selectedOption.value
                          ) || null
                        )
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={"informed"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"members"}>
                      {"Informed"}
                    </StyledFormLabel>
                    <Select
                      options={usersOptions}
                      // @ts-ignore
                      isMulti
                      value={getSelectedMembers(field.value)}
                      isLoading={isFetchingUsers}
                      onChange={(option) =>
                        setFieldValue(
                          field.name,
                          // @ts-ignore
                          option?.map(
                            (selectedOption) => selectedOption.value
                          ) || null
                        )
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Stack>
              <Field id={"externalReference"} name={"externalReference"}>
                {({ field, form: { setFieldValue }, meta }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"externalReference"}>
                      {t<string>("screens.projects.externalReference")}
                    </StyledFormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      {...field}
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <HStack>
                <Field id={"budget"} name={"budget"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"budget"}>
                        {t<string>("screens.projects.budget")}
                      </StyledFormLabel>
                      <InputGroup>
                        <InputLeftAddon children={"€"} />
                        <NumberInput
                          precision={2}
                          name={field.name}
                          value={field.value || ""}
                        >
                          <NumberInputField
                            name={field.name}
                            value={field.value || ""}
                            onChange={(event) =>
                              setFieldValue(field.name, event.target.value)
                            }
                            {...field}
                          />
                        </NumberInput>
                      </InputGroup>
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field id={"notBillable"} name={"notBillable"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl
                      isInvalid={meta.error && meta.touched}
                      style={{ paddingTop: "1.2rem" }}
                    >
                      <Checkbox
                        name={field.name}
                        isChecked={field.value}
                        onChange={(event) => {
                          console.log(field.name, event.target.checked);
                          setFieldValue(field.name, event.target.checked);
                        }}
                      >
                        {t<string>("screens.projects.notBillable")}
                      </Checkbox>
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <HStack>
                <Field
                  id={"marginabilityPercentage"}
                  name={"marginabilityPercentage"}
                >
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"marginabilityPercentage"}>
                        {t<string>("screens.projects.marginabilityPercentage")}
                      </StyledFormLabel>
                      <InputGroup>
                        <InputLeftAddon children={"%"} />
                        <NumberInput
                          precision={2}
                          name={field.name}
                          value={field.value || ""}
                        >
                          <NumberInputField
                            name={field.name}
                            value={field.value || ""}
                            onChange={(event) =>
                              setFieldValue(field.name, event.target.value)
                            }
                            {...field}
                          />
                        </NumberInput>
                      </InputGroup>
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field id={"toleranceDays"} name={"toleranceDays"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"toleranceDays"}>
                        {t<string>("screens.projects.toleranceDays")}
                      </StyledFormLabel>
                      <InputGroup>
                        <NumberInput
                          precision={2}
                          name={field.name}
                          value={field.value || 30}
                        >
                          <NumberInputField
                            name={field.name}
                            value={field.value || 30}
                            onChange={(event) =>
                              setFieldValue(field.name, event.target.value)
                            }
                            {...field}
                          />
                        </NumberInput>
                      </InputGroup>
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <Field name={"categoryId"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"categoryId"}>
                      {t<string>("screens.projects.category")}
                    </StyledFormLabel>
                    <Select
                      options={categoriesOptions}
                      value={getSelectedCategory(field.value)}
                      isLoading={isFetchingCategories}
                      onChange={(option) =>
                        setFieldValue(field.name, option?.value || null)
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <HStack>
                <Field name={"startDate"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl
                      isInvalid={meta.error && meta.touched}
                      style={{ width: "auto !important" }}
                    >
                      <StyledFormLabel htmlFor={"startDate"}>
                        {t<string>("screens.projects.startDate")}
                      </StyledFormLabel>
                      <DatePicker
                        // @ts-ignore
                        dateFormat={"dd/MM/yyyy"}
                        selectedDate={
                          props.values.startDate
                            ? new Date(props.values.startDate)
                            : undefined
                        }
                        onChange={(date) => {
                          setFieldValue("startDate", date || null);
                        }}
                      />
                    </FormControl>
                  )}
                </Field>

                <Field name={"endDate"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl
                      isInvalid={meta.error && meta.touched}
                      style={{ width: "auto !important" }}
                    >
                      <StyledFormLabel htmlFor={"endDate"}>
                        {t<string>("screens.projects.endDate")}
                      </StyledFormLabel>
                      <DatePicker
                        // @ts-ignore
                        dateFormat={"dd/MM/yyyy"}
                        selectedDate={
                          props.values.endDate
                            ? new Date(props.values.endDate)
                            : undefined
                        }
                        onChange={(date) => {
                          setFieldValue("endDate", date || null);
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </HStack>

              {props.values.uid && (
                <Field name={"statusId"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"statusId"}>
                        {t<string>("screens.projects.status")}
                      </StyledFormLabel>
                      <Select
                        options={statusOptions}
                        value={getSelectedStatus(field.value)}
                        isLoading={isFetchingProjectsWorkflows}
                        onChange={(option) =>
                          setFieldValue(field.name, option?.value || null)
                        }
                        onBlur={field.onBlur}
                      />

                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}


              <Field name={"projectType"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.error && meta.touched}>
                    <StyledFormLabel htmlFor={"projectType"}>
                      {t<string>("screens.projects.projectType")}
                    </StyledFormLabel>
                    <Select
                      options={projectTypeOptions}
                      value={getSelectedProjectTyps(field.value)}
                      // isLoading={isFetchingProjectsWorkflows}
                      onChange={(option) =>
                        setFieldValue(field.name, option?.value || null)
                      }
                      onBlur={field.onBlur}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </HStack>

          <ModalFooterForm
            onCancel={onCancel}
            onDelete={
              onDelete && project?.uid ? () => onDelete(project.uid) : undefined
            }
          />
        </Form>
      )}
    </Formik>
  );
};

// @ts-ignore
const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
